import React, { useEffect, useState } from "react";
import { airportData } from "../assets/arrays/airportData";
import { calculateDistance } from "../assets/functions/calculateDistance";
import { calculateEstimatedTime } from "../assets/functions/calculateEstimatedTime";
import Flightcard from "../components/cards/Flightcard";
import Flightcard2 from "../components/cards/Flightcard2";
import SearchDetails from "../components/details/SearchDetails";
import { useNavigate } from "react-router-dom";
import { getAirport } from "../assets/functions/getAirport";
import Footer from "../components/footers/Footer";

const ResultsPage = () => {
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState(null);
  const [distance, setDistance] = useState(null);
  const [ticketPrices, setTicketPrices] = useState({
    economy: null,
    businessClass: null,
    firstClass: null,
  });
  const [estimatedTime, setEstimatedTime] = useState(null);
  const [takeoffDateTime, setTakeoffDateTime] = useState(null);
  const [takeoffDateTime2, setTakeoffDateTime2] = useState(null);
  const [landingDateTime, setLandingDateTime] = useState(null);
  const [landingDateTime2, setLandingDateTime2] = useState(null);
  const [returnTripEnabled, setReturnTripEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data1, setdata1] = useState(null);
  const [data2, setdata2] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("searchData"));
    if (data) {
      setSearchData(data);
      const originCoordinates = getAirportCoordinates(data.departureCity);
      const destinationCoordinates = getAirportCoordinates(
        data.destinationCity
      );
      if (originCoordinates && destinationCoordinates) {
        const distance = calculateDistance(
          originCoordinates,
          destinationCoordinates
        );
        setDistance(distance);

        const estimatedTime = calculateEstimatedTime(distance);
        setEstimatedTime(estimatedTime);

        const ticketPrices = calculateTicketPrices(distance);
        setTicketPrices(ticketPrices);

        const take_offDateTime = getTakeoffDateTime(data.departureDate);
        setTakeoffDateTime(take_offDateTime);

        const landing_DateTime = calculateLandingDateTime(
          take_offDateTime,
          estimatedTime
        );
        setLandingDateTime(landing_DateTime);
        if (data.tripType === "Round trip" && data.returnDate) {
          setReturnTripEnabled(true);
          const take_offDateTime2 = getTakeoffDateTime(data.returnDate);
          setTakeoffDateTime2(take_offDateTime2);

          const landing_DateTime2 = calculateLandingDateTime(
            takeoffDateTime2,
            estimatedTime
          );
          setLandingDateTime2(landing_DateTime2);
        }
      } else {
        console.error("Invalid airport coordinates");
      }
    }
    // eslint-disable-next-line
  }, []);

  const getAirportCoordinates = (cityName) => {
    const [city, country] = cityName.split(", ");
    const airport = airportData.find(
      (item) =>
        item.city.toLowerCase() === city.toLowerCase() &&
        item.country.toLowerCase() === country.toLowerCase()
    );

    return airport
      ? {
          latitude: airport.coordinates.latitude,
          longitude: airport.coordinates.longitude,
        }
      : null;
  };

  const calculateTicketPrices = (distance) => {
    return {
      economy: 130 + distance * 0.28,
      businessClass: 180 + distance * 0.40,
      firstClass: 280 + distance * 0.55,
    };
  };

  const getTakeoffDateTime = (departureDate) => {
    const dateTimeString = Date.parse(`${departureDate}T08:00:00Z`);
    return dateTimeString;
  };

  const calculateLandingDateTime = (takeoffDateTime, estimatedTime) => {
    const timeNeeded = estimatedTime * 3600000;
    const landingTimestamp = takeoffDateTime + timeNeeded;
    return landingTimestamp;
  }; 

  const saveSelectedData = (price, ticketType, takeoffTime, landingTime) => {
    const selectedData = {
      price,
      ticketType,
      takeoffTime,
      landingTime,
      estimatedTime,
      distance,
      origin: searchData.departureCity,
      destination: searchData.destinationCity,
      passengers: searchData.passengers,
      tripType: searchData.tripType,
    };
    setdata1(selectedData);
    sessionStorage.setItem("selectedData", JSON.stringify(selectedData));
  };

  const saveSelectedData2 = (price, ticketType, takeoffTime, landingTime) => {
    if (searchData.tripType === "Round trip") {
      const selectedData2 = {
        price,
        ticketType,
        takeoffTime,
        landingTime,
        estimatedTime,
        distance,
        destination: searchData.departureCity,
        origin: searchData.destinationCity,
        tripType: searchData.tripType,
      };
      setdata2(selectedData2);
      sessionStorage.setItem("selectedData2", JSON.stringify(selectedData2));
    }
  };

  const setProceed = () => {
    if (searchData.tripType !== "Round trip" && data1 !== null) {
      return (
        <button
          onClick={() => {
            navigate("/complete-booking");
          }}
          className="py-1 w-full mt-2 bg-primary text-black text-xs transition-all ease-in-out hover:bg-gray-200 shadow"
        >
          Proceed
        </button>
      );
    } else if (
      searchData.tripType === "Round trip" &&
      data1 !== null &&
      data2 !== null
    ) {
      return (
        <button
          onClick={() => {
            navigate("/complete-booking");
          }}
          className="py-1 w-full mt-2 bg-primary text-black text-xs transition-all ease-in-out hover:bg-gray-200 shadow"
        >
          Proceed
        </button>
      );
    } else {
      return (
        <button
          className="py-1 w-full mt-2 bg-gray-200 text-black text-xs shadow"
          disabled
        >
          Proceed
        </button>
      );
    }
  };

  if (loading) {
    return (
      <div className="w-full h-screen flex justify-center items-center flex-col gap-2">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen bg-homebg bg-center bg-no-repeat bg-cover">
      {searchData && (
        <div className="w-full h-full flex flex-col gap-5 backdrop-blur-sm bg-black/30 text-white">
          <div className="w-full pt-32 max-md:pt-24 max-md:pb-6 pb-10 flex justify-center items-center gap-2 max-md:flex-col">
            <span className="text-lg text-white">
              {getAirport(searchData.departureCity)}
            </span>
            {returnTripEnabled ? (
              <i className="fa-solid fa-right-left text-xs translate-y-0.5 text-primary transition-all ease-in-out duration-700 max-md:rotate-90"></i>
            ) : (
              <i className="fa-solid fa-arrow-right text-xs translate-y-0.5 text-primary transition-all ease-in-out duration-700 max-md:rotate-90"></i>
            )}
            <span className="text-lg text-white">
              {getAirport(searchData.destinationCity)}
            </span>
          </div>
          <div className="w-full min-h-[75vh] bg-gray-100 rounded-t-xl p-5 text-black flex flex-col lg:flex-row gap-5">
            <div className="w-full lg:w-3/4 flex flex-col gap-5">
              <h2 className="text-lg">Departure flight</h2>
              <Flightcard
                ticketPrices={ticketPrices}
                takeoffDateTime={parseInt(takeoffDateTime)}
                searchData={searchData}
                landingDateTime={parseInt(landingDateTime)}
                estimatedTime={estimatedTime}
                distance={distance}
                saveSelectedData={saveSelectedData}
              />
              <Flightcard
                ticketPrices={ticketPrices}
                takeoffDateTime={parseInt(takeoffDateTime + 8 * 3600000)}
                searchData={searchData}
                landingDateTime={parseInt(landingDateTime + 8 * 3600000)}
                estimatedTime={estimatedTime}
                distance={distance}
                saveSelectedData={saveSelectedData}
              />
              {returnTripEnabled && (
                <>
                  <h2 className="text-lg">Return flight</h2>
                  <Flightcard2
                    ticketPrices={ticketPrices}
                    takeoffDateTime={parseInt(takeoffDateTime2)}
                    searchData={searchData}
                    landingDateTime={parseInt(landingDateTime2)}
                    estimatedTime={estimatedTime}
                    distance={distance}
                    saveSelectedData={saveSelectedData2}
                  />
                  <Flightcard2
                    ticketPrices={ticketPrices}
                    takeoffDateTime={parseInt(takeoffDateTime2 + 8 * 3600000)}
                    searchData={searchData}
                    landingDateTime={parseInt(landingDateTime2 + 8 * 3600000)}
                    estimatedTime={estimatedTime}
                    distance={distance}
                    saveSelectedData={saveSelectedData2}
                  />
                </>
              )}
            </div>
            <SearchDetails
              setProceed={setProceed}
              data1={data1}
              data2={data2}
              getAirport={getAirport}
              searchData={searchData}
            />
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default ResultsPage;
